import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import PageHeader from '../../layouts/page-header/PageHeader';
import VideoPopup from '../../component/video';
import videoImg from '../../assets/images/about/zabsApproved.webp';
import certificationImg from '../../assets/images/about/zabsCertificationMark.webp';
import List from '../../component/list';
import SectionTitle from '../../component/section-title/section-title';
import CategoryGridList from '../../template/category-grid';
import ActionBox from '../../component/action-box';


function Certification() {

    const listItems1 = [
        { id: 1, item: 'Affordable and practical', url: '#' },
        { id: 2, item: 'Technical business support', url: '#' },
        { id: 3, item: 'Growth and market expansion opportunities', url: '#' },
    ];
    const listItems2 = [
        { id: 1, item: 'All Micro Small and Medium Enterprises in the food value chain', url: '#' },
        { id: 2, item: 'Designed for small and micro producers.', url: '#' },
        { id: 3, item: 'Any food or beverage company that wishes to have the certification.', url: '#' },
        { id: 4, item: 'Suppliers whose products are made or packed in the Zambia', url: '$' },
    ];


    return (
        <>
            <div className="site-content">
                <PageHeader
                    title="Certification"
                    description="Zambia Bureau of Standards (ZABS) is the sole service provider for Standards 
                                Certification to the industry in Zambia."
                    breadCrumbItems={[
                        { label: 'Home', path: '/' },
                        { label: 'Pages', path: '#' },
                        { label: 'Certification', path: '#', active: true },
                    ]}
                />
                <div className="content-wrapper">
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                <Row className="justify-content-center mb-4 mt-md-5">
                                    <div className="col-xl-10">
                                        <h4 className="text-secondary">
                                            Zambia Bureau of Standards (ZABS) is the sole service provider for Standards
                                            Certification to the industry in Zambia.
                                        </h4>
                                        <p>
                                            Certification refers to confirmation or an attestation that products, processes, or
                                            systems of an organization meet the requirements of a standard or specification.
                                            ZABS provides assurance that products, services, and processes meet national or
                                            international standards/specifications. This is done through a review, assessment,
                                            or audit. ZABS offers two types of certification schemes namely Product
                                            Certification and Systems Certification
                                        </p>
                                        <p className="mb-0">
                                            ZABS offers two types of certification schemes namely Product Certification and Systems Certification
                                        </p>


                                        <hr className="light-bg" />
                                        <h4 className="mb-0 text-secondary">
                                            Product Certification
                                        </h4>
                                        <p className="mb-0">
                                            Under Product certification we ensure that a product that has passed a performance
                                            and quality assurance tests and meets the requirements of the set standard is issued
                                            with a quality mark.  Product certification is one of the quality assurances services
                                            that the Zambia Bureau of Standards (ZABS) provides to Industry. Through
                                            product certification, ZABS as a third party, attests that a product has met the
                                            requirements of a standard and is safe and fit for purpose.
                                        </p>
                                        <hr className="light-bg" />
                                        <h4 className="mb-0 text-secondary">
                                            Systems Certification
                                        </h4>
                                        <p className="mb-0">
                                            A Management System is a company’s structure for managing its processes and
                                            activities that transform inputs of resources into a product or service which meets
                                            the company’s objectives while satisfying the customers’ quality requirements,
                                            complying with regulations meeting environmental and public health objectives
                                        </p>


                                    </div>
                                </Row>

                            </Row>
                        </Container>
                    </section>

                    <section
                        className="section-pt bg-secondary-half"
                        data-bg-height="600"
                    >
                        <Container>
                            <Row className="justify-content-center">
                                <Col xl={10} lg={11}>
                                    <SectionTitle
                                        extraClass="section-title-light text-center"
                                        title="Become a Certified Local Supplier."
                                        content="The Certified Local Supplier Scheme (CLSS) supports smaller, specialized producers 
                                        by providing guidance, mentorship, and training services to help them meet quality and food 
                                        safety standards required by high-value domestic supermarkets."
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={8}>
                                    <VideoPopup
                                        videoURL="https://www.youtube.com/embed/Nq22KQvS7go"
                                        videoImage={videoImg}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <SectionTitle
                                        title="Certified Local Supplier"
                                        content="The Certified Local Supplier Scheme (CLSS) supports smaller, 
                                        specialized producers by providing guidance, mentorship, and training 
                                        services to help them meet quality and food safety standards required 
                                        by high-value domestic supermarkets."
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={5} className="pe-lg-5 mb-4 mb-md-0">
                                    <div className="mb-4">
                                        <span className="display-6 text-primary fw-bold">Why this Scheme?</span>
                                        <p>The CLSS helps local suppliers improve processes, product quality,
                                            packaging, and employee training, ensuring continuous improvement.
                                            It boosts buyer and consumer confidence in the safety and hygiene
                                            of local suppliers' products while providing cost-effective testing
                                            and certification to maintain competitiveness.</p>
                                        <hr className="light-bg" />
                                    </div>
                                    <div className="mb-4">
                                        <span className="display-5 text-primary fw-bold">
                                            Services Provided
                                        </span>
                                        <p>The scheme offers mentoring, food safety training, product testing, evaluation,
                                            packaging, labeling guidance, and adherence to local laws.</p>

                                        <hr className="light-bg" />
                                    </div>
                                    <div>
                                        <span className="display-5 text-primary fw-bold">
                                            Benefits
                                        </span>
                                        <p>What are the benefits of the scheme?</p>
                                        <List icon="fas fa-check" data={listItems1} />
                                    </div>
                                </Col>
                                <Col md={6} className="ps-lg-5">
                                    <h3 className="mb-4">
                                        Who is eligible for the scheme?
                                    </h3>
                                    <Row>
                                        <Col sm={12}>
                                            <List icon="fas fa-check" data={listItems2} />
                                        </Col>
                                    </Row>
                                    <div className="mb-4 mt-md-5">
                                        <img
                                            className="border-radius-5 w-100"
                                            src={certificationImg}
                                            alt=""
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                <SectionTitle
                                    extraClass=""
                                    title="ZABS Certified Products"
                                    content="ZABS has certified over 150 different products ranging from food and beverages to 
                                             construction materials and Petroleum products. ZABS certifies a wide range of 
                                             products including."
                                />
                                <CategoryGridList
                                    className="grid-wrapper grid-lg-4 grid-md-3 grid-sm-2 grid-xs-1"
                                    link={false}
                                />
                            </Row>
                        </Container>
                    </section>
                    <section className="section-pb">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <ActionBox
                                        btnClass="btn-primary-round text-light"
                                        title="Want to know on how you can certify your products? "
                                        content="Contact the ZABS Training and Certification section"
                                        btnText="Contact Us"
                                        btnURL="/contact"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Certification;